import type { ReactElement } from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets/types/muiSvgIcon';
import type { MarkdownRemarkFrontmatterFeatureCards } from '../../../generated/types';

type PpOffRampFeatureCard = MarkdownRemarkFrontmatterFeatureCards & {
  Icon: TpMuiSvgIcon;
};

const StyledCard = styled(Card)(({ theme }) => ({
  alignItems: 'flex-start',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export function OffRampFeatureCard({ copy, Icon, title }: PpOffRampFeatureCard): ReactElement {
  return (
    <StyledCard elevation={0}>
      <CardContent component={Stack} spacing={2}>
        <Icon htmlColor="#BDC6FA" />
        <Typography variant="paragraphBodyLBold">{title}</Typography>
        <Typography color="text.dark" variant="paragraphBodyL">
          {copy}
        </Typography>
      </CardContent>
    </StyledCard>
  );
}
