import type { ReactElement } from 'react';
import { Card, CardMedia, Grid, Stack, Typography } from '@mui/material';
import type { CardMediaProps } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatterMapCards } from '../../../generated/types';
import { FadeInViewport } from '../../components/layout/FadeInViewport';
import { HomepageSection } from '../../components/layout/HomepageSection';

const StyledCardMedia = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
  backgroundColor: theme.palette.websiteBrand.greyscale.white,
  height: '500px',
  objectFit: 'contain',
  padding: theme.spacing(6),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: '300px',
    padding: theme.spacing(2),
  },
}));

export function OffRampMapCards({
  cards,
}: {
  cards: Array<MarkdownRemarkFrontmatterMapCards>;
}): ReactElement {
  const theme = useTheme();
  return (
    <Stack
      sx={{ backgroundColor: theme.palette.websiteBrand.greyscale.lighterGrey, marginTop: 10 }}
    >
      <HomepageSection sx={{ paddingY: 12 }}>
        <Grid container>
          {cards.map((card) => (
            <Grid key={card.id} item md={6} xs={12}>
              <FadeInViewport timeout={1000}>
                <Card
                  component={Stack}
                  elevation={0}
                  padding={4}
                  spacing={2}
                  sx={{ backgroundColor: 'transparent' }}
                >
                  {card?.image?.src && <StyledCardMedia component="img" image={card.image.src} />}
                  <Typography variant="paragraphBodyLBold">{card.title}</Typography>
                  <Typography color="text.dark" variant="paragraphBodyL">
                    {card.copy}
                  </Typography>
                </Card>
              </FadeInViewport>
            </Grid>
          ))}
        </Grid>
      </HomepageSection>
    </Stack>
  );
}
