import type { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InquiryButton } from '../../components/buttons/InquiryButton';
import { GrowInViewport } from '../../components/layout/GrowInViewport';
import { HomepageSection } from '../../components/layout/HomepageSection';

type PpOffRampGetStarted = {
  button: string | null | undefined;
  copy: string | null | undefined;
  title: string | null | undefined;
};

const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: `linear-gradient(180deg, #FFFFFF 0.04%, #FFFFFF 57.17%, #F3F5FF 100.04%)`,
  border: `1px solid ${theme.palette.websiteBrand.greyscale.grey}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(10),
  padding: theme.spacing(12, 4),
}));

export function OffRampGetStarted({ button, copy, title }: PpOffRampGetStarted): ReactElement {
  return (
    <HomepageSection>
      <GrowInViewport timeout={1000}>
        <StyledStack spacing={2} textAlign="center">
          <Typography variant="h5">{title}</Typography>
          <Typography variant="paragraphBodyLBold">{copy}</Typography>
          <InquiryButton variant="contained">{button}</InquiryButton>
        </StyledStack>
      </GrowInViewport>
    </HomepageSection>
  );
}
