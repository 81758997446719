import type { ReactElement } from 'react';
import { Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatterComplianceCards } from '../../../generated/types';
import { FadeInViewport } from '../../components/layout/FadeInViewport';
import { HomepageSection } from '../../components/layout/HomepageSection';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  ':hover': {
    backgroundSize: '110%',
  },
  border: `1px solid ${theme.palette.websiteBrand.greyscale.grey}`,
  borderRadius: theme.shape.borderRadius,
  height: 300,
  [theme.breakpoints.down('sm')]: {
    height: 200,
  },
}));
export function OffRampCompliance({
  cards,
}: {
  cards: Array<MarkdownRemarkFrontmatterComplianceCards>;
}): ReactElement {
  return (
    <HomepageSection>
      <Grid container>
        {cards?.map((card) => (
          <Grid key={card.id} item md={4} xs={12}>
            <FadeInViewport timeout={1000}>
              <StyledCard elevation={0}>
                <CardContent component={Stack} spacing={2}>
                  {card?.image && <StyledCardMedia image={card.image} />}
                  <Typography variant="paragraphBodyLBold">{card.title}</Typography>
                  <Typography color="text.dark" variant="paragraphBodyM">
                    {card.copy}
                  </Typography>
                </CardContent>
              </StyledCard>
            </FadeInViewport>
          </Grid>
        ))}
      </Grid>
    </HomepageSection>
  );
}
