import type { ReactElement } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InquiryButton } from '../../components/buttons/InquiryButton';
import { FadeInViewport } from '../../components/layout/FadeInViewport';
import { GrowInViewport } from '../../components/layout/GrowInViewport';
import { HomepageSection } from '../../components/layout/HomepageSection';
import type { PpMarkdownImageWithAlt } from '../../images/types';

type PpOffRampHero = {
  button: string | null | undefined;
  image: PpMarkdownImageWithAlt | undefined;
  subtitle: string | null | undefined;
  title: string | null | undefined;
};

const StyledImage = styled('img')(() => ({
  filter: 'drop-shadow(0px 24px 40px rgba(0, 0, 0, 0.1))',
}));

export function OffRampHero({ button, image, subtitle, title }: PpOffRampHero): ReactElement {
  return (
    <HomepageSection>
      <Grid container>
        <Grid item md={5} xs={12}>
          <FadeInViewport timeout={1000}>
            <Stack alignItems="flex-start" height="100%" justifyContent="center" spacing={2}>
              <Typography component="h1" variant="h3">
                {title}
              </Typography>
              <Typography color="text.dark" variant="paragraphBodyL">
                {subtitle}
              </Typography>
              <InquiryButton color="primary" variant="contained">
                {button}
              </InquiryButton>
            </Stack>
          </FadeInViewport>
        </Grid>
        <Grid md={1} xs={false} />
        <Grid item md={6} sx={{ display: { md: 'flex', xs: 'none' } }}>
          <GrowInViewport timeout={1000}>
            <Stack height="100%" width="100%">
              {image?.src && <StyledImage alt={image.alt || ''} src={image.src} />}
            </Stack>
          </GrowInViewport>
        </Grid>
      </Grid>
    </HomepageSection>
  );
}
