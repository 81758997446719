import type { ReactElement } from 'react';
import { graphql } from 'gatsby';
import type { PageProps } from 'gatsby';
import type {
  MarkdownRemarkFrontmatterComplianceCards,
  MarkdownRemarkFrontmatterFeatureCards,
  MarkdownRemarkFrontmatterMapCards,
  OffRampPageQuery,
} from '../../generated/types';
import { HomepageTemplate } from '../components/templates';
import { OffRampApi } from '../modules/offramp/OffRampApi';
import { OffRampCompliance } from '../modules/offramp/OffRampCompliance';
import { OffRampFeatures } from '../modules/offramp/OffRampFeatures';
import { OffRampGetStarted } from '../modules/offramp/OffRampGetStarted';
import { OffRampHero } from '../modules/offramp/OffRampHero';
import { OffRampMapCards } from '../modules/offramp/OffRampMapCards';

type PpOffRampPage = PageProps<OffRampPageQuery>;

function OffRampPage({ data: { content } }: PpOffRampPage): ReactElement {
  return (
    <HomepageTemplate seo={content?.frontmatter?.seo}>
      <OffRampHero
        button={content?.frontmatter?.offrampHeroButton}
        image={content?.frontmatter?.offrampHeroImage}
        subtitle={content?.frontmatter?.offrampHeroSubtitle}
        title={content?.frontmatter?.offrampHeroTitle}
      />
      <OffRampFeatures
        cards={content?.frontmatter?.featureCards as Array<MarkdownRemarkFrontmatterFeatureCards>}
        subtitle={content?.frontmatter?.featureCardsSubtitle}
        title={content?.frontmatter?.featureCardsTitle}
      />
      <OffRampMapCards
        cards={content?.frontmatter?.mapCards as Array<MarkdownRemarkFrontmatterMapCards>}
      />
      <OffRampCompliance
        cards={
          content?.frontmatter?.complianceCards as Array<MarkdownRemarkFrontmatterComplianceCards>
        }
      />
      <OffRampApi
        button={content?.frontmatter?.apiButton}
        copy={content?.frontmatter?.apiCopy}
        image={content?.frontmatter?.apiImage}
        subtitle={content?.frontmatter?.apiSubtitle}
        title={content?.frontmatter?.apiTitle}
      />
      <OffRampGetStarted
        button={content?.frontmatter?.getStartedButton}
        copy={content?.frontmatter?.getStartedCopy}
        title={content?.frontmatter?.getStartedTitle}
      />
    </HomepageTemplate>
  );
}

export const data = graphql`
  query OffRampPage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "offramp" }, locale: { eq: $locale } }) {
      frontmatter {
        locale
        seo {
          title
          description
          image
        }
        offrampHeroTitle
        offrampHeroSubtitle
        offrampHeroButton
        offrampHeroImage {
          src
          alt
        }
        featureCardsTitle
        featureCardsSubtitle
        featureCards {
          id
          title
          copy
        }
        mapCards {
          id
          title
          copy
          image {
            src
            alt
          }
        }
        complianceCards {
          id
          title
          copy
          image
        }
        apiTitle
        apiSubtitle
        apiCopy
        apiButton
        apiImage {
          src
          alt
        }
        getStartedTitle
        getStartedCopy
        getStartedButton
      }
    }
  }
`;

export default OffRampPage;
