import type { ReactElement } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { webLinks } from '@noah-labs/shared-constants';
import { FadeInViewport } from '../../components/layout/FadeInViewport';
import { GrowInViewport } from '../../components/layout/GrowInViewport';
import { HomepageSection } from '../../components/layout/HomepageSection';
import type { PpMarkdownImageWithAlt } from '../../images/types';

type PpOffRampApi = {
  button: string | null | undefined;
  copy: string | null | undefined;
  image: PpMarkdownImageWithAlt | undefined;
  subtitle: string | null | undefined;
  title: string | null | undefined;
};

export function OffRampApi({ button, copy, image, subtitle, title }: PpOffRampApi): ReactElement {
  return (
    <HomepageSection>
      <Grid container justifyContent="space-between" rowSpacing={2}>
        <Grid item md={5} xs={12}>
          <FadeInViewport timeout={1000}>
            <Stack alignItems="flex-start" height="100%" justifyContent="center" spacing={2}>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="subHeadingM">{subtitle}</Typography>
              <Typography color="text.dark" variant="paragraphBodyL">
                {copy}
              </Typography>
              <Button color="primary" href={webLinks.docs.path} variant="contained">
                {button}
              </Button>
            </Stack>
          </FadeInViewport>
        </Grid>
        <Grid item md={6} xs={12}>
          <GrowInViewport timeout={1000}>
            <Stack height="100%" width="100%">
              {image?.src && <img alt={image.alt || ''} src={image.src} />}
            </Stack>
          </GrowInViewport>
        </Grid>
      </Grid>
    </HomepageSection>
  );
}
