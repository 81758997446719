import type { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { BankIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BankIcon';
import { ContactIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ContactIcon';
import { DocumentIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DocumentIcon';
import { GlobeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/GlobeIcon';
import type { MarkdownRemarkFrontmatterFeatureCards } from '../../../generated/types';
import { CustomCarousel } from '../../components/carousel/CustomCarousel';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { HomepageSection } from '../../components/layout/HomepageSection';
import { OffRampFeatureCard } from './OffRampFeatureCard';

type PpOffRampFeatures = {
  cards: Array<MarkdownRemarkFrontmatterFeatureCards>;
  subtitle: string | null | undefined;
  title: string | null | undefined;
};

export const featuresIcons = [GlobeIcon, BankIcon, ContactIcon, DocumentIcon];

export function OffRampFeatures({ cards, subtitle, title }: PpOffRampFeatures): ReactElement {
  const { isSmall } = useBreakpoints();

  return (
    <HomepageSection>
      <Grid container columnSpacing={4}>
        <Grid item md={4} xs={12}>
          <Typography variant="paragraphBodyMBold">{title}</Typography>
          <Typography color="text.dark" variant="paragraphBodyM">
            {subtitle}
          </Typography>
        </Grid>
        <Grid container item columnSpacing={4} md={8} rowSpacing={4} xs={12}>
          {isSmall ? (
            <Grid item xs={12}>
              <CustomCarousel
                autoPlay
                infiniteLoop
                preventMovementUntilSwipeScrollTolerance
                showIndicators
                swipeable
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                width="100%"
              >
                {cards.map((card, index) => (
                  <OffRampFeatureCard
                    key={card.id}
                    copy={card.copy}
                    Icon={featuresIcons[index]}
                    title={card.title}
                  />
                ))}
              </CustomCarousel>
            </Grid>
          ) : (
            cards.map((card, index) => (
              <Grid key={card.id} item md={6}>
                <OffRampFeatureCard
                  key={card.id}
                  copy={card.copy}
                  Icon={featuresIcons[index]}
                  title={card.title}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </HomepageSection>
  );
}
