import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ContainerProps } from '@mui/system';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

type PpHomepageSection = ContainerProps & PpWC & { fullScreen?: boolean };

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(15, 3, 0),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(10, 3, 0),
  },
}));

export function HomepageSection({ children, ...props }: PpHomepageSection): React.ReactElement {
  return (
    <section>
      <StyledContainer {...props} disableGutters fixed maxWidth="lg">
        {children}
      </StyledContainer>
    </section>
  );
}
